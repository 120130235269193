import IconClose from '@/components/icons/IconClose.vue';
import ProductsCategoryFilter from '@/components/products/ProductsCategoryFilter/index.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductsFilters',
  components: { ProductsCategoryFilter, IconClose },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change-filters'],
  data() {
    return {};
  },

  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      filters: 'selection/filters'
    })
  },
  methods: {
    onResetFilter() {
      this.$router.push({ name: 'selection' }).catch(() => {});
    }
  }
};
