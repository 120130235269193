import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

//---helpers
import { globalLoader, MEDIA } from '@/helpers/variables';
import { PRODUCTS_ORDER_KEY } from '@/helpers';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import ProductsBlock from '@/components/products/ProductsBlock/index.vue';
import ProductsFilters from '@/components/products/ProductsFilter/index.vue';
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'Selection',
  mixins: [dynamicHead],
  components: {
    ProductsFilters,
    ProductsBlock,
    BreadcrumbsSimple
  },
  data() {
    return {
      MEDIA,
      selectionPage: 1,
      selectionProducts: [],
      selectionProductsMeta: null,
      isSelectionProductsLoading: false,
      isInitProductsLoad: true
    };
  },
  created() {
    globalLoader(true);
    this._loadContent();
  },
  watch: {
    $route: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this._getProducts();
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      appSize: 'app/size',
      metaData: 'meta/metaData'
    }),
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.selectionProducts;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      return _.map(this.selectionProducts, markAsSelected);
    }
  },
  methods: {
    ...mapActions({
      getSelectionFilters: 'selection/GET_SELECTION_FILTERS',
      getSelectionProducts: 'selection/GET_SELECTION_PRODUCTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _getProducts() {
      this.isSelectionProductsLoading = true;
      let { orderby, ['product-page']: page, filters } = this.$route.query;

      let params = {
        page: page ? Number(page) : 1,
        sort_filter_type: orderby ? orderby : PRODUCTS_ORDER_KEY.BESTSELLERS
      };

      if (this.isInitProductsLoad && params.page > 1) {
        params = { ...params, page: 1, per_page: params.page * 12 };
      }

      if (filters) {
        let categories = filters.split('|').reduce((acc, categoryString) => {
          let idsArray = categoryString.slice(categoryString.indexOf('[') + 1, categoryString.indexOf(']')).split('-');
          return [...acc, ...idsArray];
        }, []);
        params = { ...params, categories: categories.join(',') };
      }

      return this.getSelectionProducts(params)
        .then((result) => {
          this.selectionProducts = page ? [...this.selectionProducts, ...result.data] : result.data;
          this.selectionProductsMeta = result.meta;
        })
        .finally(() => {
          this.isSelectionProductsLoading = false;
        });
    },

    async _loadContent() {
      let promiseCollection = [];

      promiseCollection.push(this.getSelectionFilters());
      promiseCollection.push(this._getProducts());
      promiseCollection.push(
        this.getMetaData('podbor-kosmetiki').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection).then();
      this.isInitProductsLoad = false;

      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    },
    onChangePage() {
      let { query } = this.$route;
      let { ['product-page']: page } = query;
      let editQuery = { ...query, ['product-page']: page ? Number(page) + 1 : 2 };
      this.$router.push({ name: 'selection', query: editQuery }).catch(() => {});
    }
  }
};
